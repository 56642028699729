<template>
    <div>
        <!--添加-->
        <el-row :gutter="10">
            <el-col :span="6">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addDialogVisible=true">添加类型
                </el-button>
            </el-col>
        </el-row>

        <el-table :data="typeList">
            <el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
            <el-table-column label="名称" prop="type_name" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)">编辑
                    </el-button>
                    <el-popconfirm title="确定删除吗？"
                                   icon="el-icon-delete"
                                   iconColor="red"
                                   @confirm="deletetypeById(scope.row.id)">
                        <el-button size="mini" type="danger" icon="el-icon-delete" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.pageNum"
                       :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize" :total="total"
                       layout="total, sizes, prev, pager, next, jumper" background>
        </el-pagination>

        <!--添加类型对话框-->
        <el-dialog title="添加类型" width="50%" :visible.sync="addDialogVisible" :close-on-click-modal="false"
                   @close="addDialogClosed">
            <!--内容主体-->
            <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="80px">
                <el-form-item label="类型名称" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item>
            </el-form>
            <!--底部-->
            <span slot="footer">
				<el-button @click="addDialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="addType">确 定</el-button>
			</span>
        </el-dialog>

        <!--编辑类型对话框-->
        <el-dialog title="编辑类型" width="50%" :visible.sync="editDialogVisible" :close-on-click-modal="false"
                   @close="editDialogClosed">
            <!--内容主体-->
            <el-form :model="editForm" :rules="formRules" ref="editFormRef" label-width="80px">
                <el-form-item label="类型名称" prop="name">
                    <el-input v-model="editForm.name"></el-input>
                </el-form-item>
            </el-form>
            <!--底部-->
            <span slot="footer">
				<el-button @click="editDialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="editType">确 定</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
    import {getData, addType, editType, deleteTypeById} from '@/api/type'

    export default {
        name: "typeList",
        data() {
            return {
                queryInfo: {
                    pageNum: 1,
                    pageSize: 10
                },
                typeList: [],
                total: 0,
                addDialogVisible: false,
                editDialogVisible: false,
                addForm: {
                    name: '',
                },
                editForm: {},
                formRules: {
                    name: [{required: true, message: '请输入类型名称', trigger: 'blur'}]
                },
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                getData(this.queryInfo).then(res => {
                    console.log(res.data)
                    this.typeList = res.data.types
                    this.total = res.data.total
                })
            },
            //监听 pageSize 改变事件
            handleSizeChange(newSize) {
                this.queryInfo.pageSize = newSize
                this.getData()
            },
            //监听页码改变事件
            handleCurrentChange(newPage) {
                this.queryInfo.pageNum = newPage
                this.getData()
            },
            addDialogClosed() {
                this.$refs.addFormRef.resetFields()
            },
            editDialogClosed() {
                this.editForm = {}
                this.$refs.editFormRef.resetFields()
            },
            addType() {
                this.$refs.addFormRef.validate(valid => {
                    if (valid) {
                        addType(this.addForm).then(res => {
                            this.msgSuccess(res.message)
                            this.addDialogVisible = false
                            this.getData()
                        })
                    }
                })
            },
            editType() {
                this.$refs.editFormRef.validate(valid => {
                    if (valid) {
                        editType(this.editForm).then(res => {
                            this.msgSuccess(res.message)
                            this.editDialogVisible = false
                            this.getData()
                        })
                    }
                })
            },
            showEditDialog(row) {
                this.editForm = {...row}
                this.editDialogVisible = true
            },
            deleteTypeById(id) {
                deleteTypeById(id).then(res => {
                    this.msgSuccess(res.message)
                    this.getData()
                })
            }
        }
    }
</script>

<style scoped>
    .el-button + span {
        margin-left: 10px;
    }
    .me-red {
        background: #DD3C3C;
    }

    .me-orange {
        background: #F27E31;
    }

    .me-yellow {
        background: #FAC21F;
    }

    .me-olive {
        background: #BBCF2D;
    }

    .me-green {
        background: #36BF56;
    }

    .me-teal {
        background: #18BBB3;
    }

    .me-blue {
        background: #368FD3;
    }

    .me-violet {
        background: #7248CD;
    }

    .me-purple {
        background: #AB46CC;
    }

    .me-pink {
        background: #E14BA0;
    }

    .me-brown {
        background: #AC7551;
    }

    .me-grey {
        background: #828282;
    }

    .me-black {
        background: #303132;
    }
</style>