import axios from '@/util/request'

export function getData(queryInfo) {
	return axios({
		url: 'type/get',
		method: 'GET',
		params: {
			...queryInfo
		}
	})
}

export function addType(form) {
	return axios({
		url: 'type/add',
		method: 'POST',
		data: {
			type_name:form.name,
			color:form.color
		}
	})
}

export function editType(form) {
	return axios({
		url: 'type/update',
		method: 'POST',
		data: {
			...form
		}
	})
}

export function deleteTypeById(id) {
	return axios({
		url: 'type/delete',
		method: 'DELETE',
		params: {
			id
		}
	})
}